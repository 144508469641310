export const chapters = {
    welcome: 'welcome',
    whatsNew: 'whatsNew',
    feedback: 'feedback',
    features: {
        mapAndListView: 'features.mapAndListView',
        mapSearch: 'features.mapSearch',
        assetMarkers: 'features.assetMarkers',
        mapSettings: 'features.mapSettings',
        assetSelection: 'features.assetSelection',
        poiSidebar: 'features.poiSidebar',
        route: 'features.route',
        vehicleSidebar: 'features.vehicleSidebar',
        history: 'features.history',
        positionSharing: 'features.positionSharing',
    },
};
